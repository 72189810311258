<template>
  <div class="card" no-body :dir="categoryID === 'Hotel_Only' ? 'ltr' : ''">
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        block
        v-b-toggle="`collapsed-item-${index}`"
        variant="primary"
        class="btn-header-link"
        >{{ item.title }}</b-button
      >
    </b-card-header>

    <b-collapse :id="`collapsed-item-${index}`" v-model="isVisible">
      <b-card v-if="item.type === 'map'">
        <Map :width="'100%'" :height="'250'" :address="item.address" />
      </b-card>
      <b-card v-else-if="item.type === 'about'" class="text-left">
        {{ item.message }}
      </b-card>
      <b-card v-else-if="item.type === 'facilities'" class="text-left">
        <p>
          <Facilitie
            v-for="(elm, index) in item.fac"
            :key="index"
            :data="elm"
            direction="left"
          />
        </p>
      </b-card>
      <b-card v-else-if="item.type === 'fac'">
        {{ item.message }}
        <p>
          <Facilitie
            v-for="(elm, index) in item.fac"
            :key="index"
            :data="item.translations.fac[elm.code][lang]"
          />
        </p>
      </b-card>
      <b-card v-else-if="item.type === 'list'">
        <ul v-html="item.message"></ul>
      </b-card>
      <b-card v-else-if="item.type === 'html'">
        <div v-html="item.message"></div>
      </b-card>
      <b-card v-else>
        {{ item.message }}
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BButton,
  BCollapse,
  VBToggle,
  BCard,
  BCardHeader,
} from 'bootstrap-vue';

export default {
  components: {
    BButton,
    BCollapse,
    BCard,
    BCardHeader,
    Facilitie: () => import('@/components/productPage/atoms/Facilitie'),
    Map: () => import('@/components/productPage/atoms/GoogleMap'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isVisible: false,
      categoryID: '',
    };
  },
  async created() {
    this.categoryID = this.$route.query.categoryId;
  },
};
</script>

<style scoped>
header button.btn {
  text-align: inherit;
}
.card .card-header .btn-header-link.collapsed:after {
  content: '\f107';
}
.card .card-header .btn-header-link:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: left;
}
.card {
  margin-bottom: 5px;
}
</style>
